<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>CARI</span>
                            <el-input v-model="filter.search" placeholder="Search" @keyup.enter="onFilter()" clearable />
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3" v-show="false">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                </svg>
                            </span>
                            Export
                        </button>
                        <button class="btn btn-primary" @click="modalClick('add', null)">Create</button>
                    </div>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive" v-show="false">
                <span>Hasil filter</span>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th>Name</th>
                            <th>Jenis</th>
                            <th>Created At</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-50px">{{ item.index }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.jenis }}</td>
                            <td>{{ item.created_at }}</td>
                            <td class="text-end">
                                <div class="d-flex justify-content-end">
                                    <button @click="modalClick('edit', item)" class="btn btn-secondary btn-sm me-1" title="Edit">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>
        <el-dialog v-model="modal" :title="modalTitle" width="600px" :close-on-click-modal="true">
            <el-form
                ref="formRef"
                :model="tempData"
                class="form w-100 my-5"
            >
                <input type="hidden" name="id" v-model="tempData.id" disabled />
                <div class="scroll-y me-n7 pe-7">
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Nama</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="name"
                        :rules="[
                          { required: true, message: 'Name is required' },
                        ]"
                      >
                        <el-input v-model="tempData.name" name="name" class="form-control p-0 border-0" placeholder="Masukkan nama" size="large"></el-input>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Jenis</label>
                        <el-form-item
                          class="fv-row mb-7"
                          prop="jenis"
                          :rules="[
                            { required: true, message: 'Jenis is required' },
                          ]"
                        >
                          <el-select name="jenis" v-model="tempData.jenis" class="form-control p-0 border-0" placeholder="Pilih jenis" size="large">
                            <el-option
                              v-for="(item, i) in ['MAIN_PRODUCT']"
                              :key="i"
                              :label="item"
                              :value="item"
                            >
                              <div class="d-flex">
                                <div class="col me-3">{{ item }}</div>
                              </div>
                            </el-option>
                          </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modal = false">Cancel</button>
                    <button
                        type="button"
                        ref="submitButton"  
                        class="btn btn-primary"
                        :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                        @click="onSubmit(formRef, submitType)"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination, FormInstance, FormRules } from 'element-plus'

export default defineComponent({
    components: { 
        //Datatable,
    },
    setup() {
        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingTable = ref(false);
        const loadingSubmit = ref(false)
        const tableRef = ref(null)

        const filterActive = ref(false)
        const filter = reactive({
            jenis: null,
            search: null,
        })

        const tableData = reactive([]);

        const formRef = ref(null)

        const tempData = reactive({
            id: null,
            name: '',
            jenis: 'MAIN_PRODUCT',
        })
        
        const submitType = ref('')

        const modal = ref(false)
        const modalTitle = ref('')
        
        const modalClick = async(type, data = null) => {
            try {
                submitType.value = type

                await Object.keys(tempData).map(k => tempData[k] = '')

                if(type == 'add') {
                    modalTitle.value = 'Add New Category'
                    modal.value = true
                }
                if(type == 'edit') {
                    await Object.assign(tempData, data);

                    modalTitle.value = 'Edit Category'
                    modal.value = true
                }
            } catch(e) {
                console.log(e)
            }
        }

        const onFilter = async (type) => {
            try {
                filterActive.value = false
            
                loadingTable.value = true

                await getData()

                loadingTable.value = false

                Object.values(filter).every(o => o == null || o.length == 0)
                    ? filterActive.value = false
                    : filterActive.value = true

            } catch(e) {
                console.error(e)
            }
        }

        const resetFilter = async (type) => {
            try {
                Object.keys(filter).map(key => filter[key] = null)

                loadingTable.value = true
                filterActive.value = false

                await getData()

                loadingTable.value = false

            } catch(e) {
                console.error(e)
            }
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                let search = filter.search ? '&search='+filter.search : '';
                let jenis = filter.jenis ? '&jenis='+filter.jenis : '';

                await ApiService.get("category/list_category?page=" + page + jenis + search)
                .then(async ({ data }) => {
                    pagination.value = data.data.pagination

                    Object.assign(tableData, data.data.data)
                })
                .catch(({ response }) => {
                    console.log('Error getting list category');
                });

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        const onSubmit = async(formEl, type) => {
            try {
                if (!formEl) return

                await formEl.validate( async(valid, fields) => {
                    loadingSubmit.value = true;

                    if(valid) {
                        let endpoint = '';

                        if(type == 'add') {
                            endpoint = '/category/create';
                        }
                        if(type == 'edit') {
                            endpoint = '/category/update/'+tempData.id;
                        }

                        const data = {
                            id: tempData.id,
                            name: tempData.name,
                            jenis: tempData.jenis
                        }

                        await ApiService.post(endpoint, data)
                        .then(async({ data }) => {
                            if(data.error) {
                                throw new Error(data.messages)
                            }

                            modal.value = false

                            Swal.fire({
                                title: data.messages,
                                icon: "success",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000,
                            })

                            await getData()
                        })
                        .catch((response) => {
                            throw new Error(response)
                        });

                    } else {
                        throw new Error('Please check form before submit.')
                    }
                    
                    loadingSubmit.value = false;
                })
                

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
                    
        }

        onMounted(async() => {
            setCurrentPageBreadcrumbs("Master Kategori", ["Master Kategori"]);

            getData()
        })

        return {
            loadingTable, loadingSubmit, tableRef, img_url_mockup, img_url_desain, img_url_logo,
            modal, modalTitle, modalClick,
            onFilter, resetFilter, filter, filterActive,
            tableData, tempData, submitType, onSubmit, formRef,
            handlePageChange, pagination,
            formatIDR,
        }
    }
})
</script>